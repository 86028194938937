<template>
  <div class="login-layout">
    <header>
      <img @click="toMain" src="@/assets/img/B_logo.svg" alt="logo" />
      <Button @click="toMain" mode="white">{{ AUTH_TRANSLATE?.common.btn.home }}</Button>
    </header>
    <div class="main">
      <section class="left-box">
        <slot></slot>
        <div class="message-bottom">
          <p class="m-text-des">
            {{ footerText }} <span @click="toSignUp">{{ footerLink }} </span>
          </p>
        </div>
      </section>
      <section :style="`background-image:url('${AUTH_TRANSLATE?.auth.background.url}')`" class="right-box"></section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Button from "./parts/button";
export default {
  components: {
    Button
  },
  props: {
    buttonText: {
      type: String,
      default: "To Main PAge"
    },
    footerText: {
      type: String
    },
    footerLink: {
      type: String
    },
    footerRouteName: {
      type: String,
      default: "SignUp"
    }
  },
  computed: {
    ...mapGetters(["AUTH_TRANSLATE"])
  },
  methods: {
    ...mapActions(["AuthTranslate"]),
    toMain() {
      this.$router.push({ name: "Main" });
    },
    toSignUp() {
      this.$router.push({ name: this.footerRouteName });
    }
  },
  created() {
    this.AuthTranslate();
  }
};
</script>

<style lang="scss" scoped>
.login-layout {
  width: 100%;
  height: 100%;
}
header {
  position: absolute;
  top: 0;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 21px;
  img {
    margin-top: 5px;
    align-self: flex-start;
    cursor: pointer;
  }
}
.main {
  min-height: 100%;
  display: flex;
  background: var(--bg-main);
}
.left-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 710px;
  background: var(--bg-white);
}
.right-box {
  margin-top: 250px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  // background-image: url("~@/assets/img/login-bg3.png");

  @media (max-width: 764px) {
    background-position-y: 46%;
  }
}
.message-bottom {
  position: absolute;
  bottom: 20px;
  left: 20px;

  @media (max-width: 1199px) {
    position: static;
    margin: 39px 0 20px 20px;
  }

  .m-text-des {
    color: var(--dark-40);
  }

  span {
    cursor: pointer;
    color: var(--blue-main);
  }
}
</style>
